define("ember-data-table/components/data-table-content", ["exports", "ember-data-table/templates/components/data-table-content"], function (_exports, _dataTableContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTableContent.default,
    classNames: ["data-table-content"],
    tableClass: Ember.computed.alias("data-table.tableClass")
  });

  _exports.default = _default;
});