define("ember-freestyle/templates/components/freestyle-showdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OGy9a4Kq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"markdown\",\"tables\"],[[35,0],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-showdown-content.hbs"
    }
  });

  _exports.default = _default;
});