define("ember-freestyle/templates/components/freestyle-variant-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QHfv+F8k",
    "block": "{\"symbols\":[\"variantKey\"],\"statements\":[[10,\"ul\"],[14,0,\"FreestyleCollection-variantList\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[31,[\"FreestyleCollection-variantListItem \",[30,[36,2],[[30,[36,1],[[35,0],[32,1]],null],\"FreestyleCollection-variantListItem--active\"],null]]]],[15,\"onclick\",[30,[36,3],[[32,0],\"onClickItem\",[32,1]],null]],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"activeKey\",\"variant-eq\",\"if\",\"action\",\"variants\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-variant-list.hbs"
    }
  });

  _exports.default = _default;
});