define("ember-data-table/templates/components/data-table-content-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YKyHCwku",
    "block": "{\"symbols\":[\"field\",\"&default\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"field\",\"label\",\"currentSorting\"],[[32,1],[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sort\",\"th-sortable\",\"fields\",\"-track-array\",\"each\",\"enableSelection\",\"if\",\"enableLineNumbers\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/data-table-content-header.hbs"
    }
  });

  _exports.default = _default;
});