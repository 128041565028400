define("ember-freestyle/components/freestyle-variant", ["exports", "ember-freestyle/templates/components/freestyle-variant"], function (_exports, _freestyleVariant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleVariant.default,
    classNames: ['FreestyleVariant'],
    classNameBindings: ['inline:FreestyleVariant--inline'],
    inline: Ember.computed('collection.{inline,activeKey}', function () {
      var activeKey = this.get('collection.activeKey');
      return this.get('collection.inline') && activeKey === 'all';
    }),
    show: Ember.computed('collection.activeKey', function () {
      var activeKey = this.get('collection.activeKey');
      return activeKey === 'all' || activeKey === this.get('key');
    }),
    willRender: function willRender() {
      this._super.apply(this, arguments);

      this.get('collection').registerVariant(this.get('key'));
    }
  });

  _exports.default = _default;
});