define("ember-data-table/components/data-table-content-header", ["exports", "ember-data-table/templates/components/data-table-content-header"], function (_exports, _dataTableContentHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTableContentHeader.default,
    tagName: 'thead',
    sort: Ember.computed.alias("data-table.sort"),
    fields: Ember.computed.oneWay("data-table.parsedFields")
  });

  _exports.default = _default;
});