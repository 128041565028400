define("ember-can/initializers/setup-ember-can", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  _emberResolver.default.reopen({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('pluralizedTypes.ability', 'abilities');
    }
  });

  function initialize() {}

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});