define("ember-data-table/templates/components/data-table-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OwSn/AM8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"table\"],[15,0,[31,[\"data-table \",[34,9]]]],[12],[2,\"\\n\"],[6,[37,10],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,8],null,[[\"header\",\"body\"],[[30,[36,3],[\"data-table-content-header\"],[[\"enableSelection\",\"enableLineNumbers\",\"data-table\"],[[35,2],[35,1],[35,0]]]],[30,[36,3],[\"data-table-content-body\"],[[\"content\",\"enableSelection\",\"enableLineNumbers\",\"noDataMessage\",\"onClickRow\",\"data-table\"],[[35,7],[35,2],[35,1],[35,6],[30,[36,5],[[35,4]],null],[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,3],[\"data-table-content-header\"],[[\"enableSelection\",\"enableLineNumbers\",\"data-table\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n    \"],[1,[30,[36,3],[\"data-table-content-body\"],[[\"content\",\"enableSelection\",\"enableLineNumbers\",\"noDataMessage\",\"onClickRow\",\"data-table\"],[[35,7],[35,2],[35,1],[35,6],[30,[36,5],[[35,4]],null],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data-table\",\"enableLineNumbers\",\"enableSelection\",\"component\",\"onClickRow\",\"optional\",\"noDataMessage\",\"content\",\"hash\",\"tableClass\",\"if\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/data-table-content.hbs"
    }
  });

  _exports.default = _default;
});