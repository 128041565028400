define("ember-freestyle/components/freestyle-variant-list", ["exports", "ember-freestyle/templates/components/freestyle-variant-list"], function (_exports, _freestyleVariantList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleVariantList.default,
    actions: {
      onClickItem: function onClickItem(variantKey) {
        this.onClickVariant(variantKey);
      }
    }
  });

  _exports.default = _default;
});