define("ember-data-table/components/data-table-menu-selected", ["exports", "ember-data-table/templates/components/data-table-menu-selected"], function (_exports, _dataTableMenuSelected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTableMenuSelected.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('data-table.enableSelection', true);
    },
    selectionCount: Ember.computed('data-table.selection.[]', function () {
      return this.get('data-table.selection.length');
    }),
    actions: {
      clearSelection: function clearSelection() {
        this.get('data-table').clearSelection();
      }
    }
  });

  _exports.default = _default;
});