define("ember-freestyle/components/freestyle-source", ["exports", "ember-freestyle/templates/components/freestyle-source"], function (_exports, _freestyleSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    layout: _freestyleSource.default,
    initialHighlightComplete: false,
    useDoubleQuotesForStrings: false,
    emberFreestyle: Ember.inject.service(),
    _dynamafy: function _dynamafy(sourceCode) {
      var _this = this;

      var dynamicProperties = this.get("dynamicProperties");
      Object.keys(dynamicProperties).forEach(function (property) {
        var propertyValue = Ember.get(dynamicProperties, "".concat(property, ".value"));

        var type = _typeof(propertyValue);

        var quote = _this.get("useDoubleQuotesForStrings") ? "\"" : "'"; // First, replace in-block dynamic properties

        sourceCode = sourceCode.replace("{{dynamic.".concat(property, "}}"), propertyValue);
        var renderedValue = type === "string" ? "".concat(quote).concat(propertyValue).concat(quote) : propertyValue;
        sourceCode = sourceCode.replace("dynamic.".concat(property), renderedValue);
      });
      return sourceCode;
    },
    renderableSource: Ember.computed("dynamicProperties", "source", function () {
      var result = this.get("source").replace(/^(\s*\n)*/, "").replace(/\s*$/, "");

      if (this.get("isDynamic")) {
        result = this._dynamafy(result);
      }

      if (this.get("initialHighlightComplete")) {
        var pre = this.element.querySelector("pre");

        if (pre) {
          // highlight.js breaks binding, so we need to manually reset the innerText on changes
          pre.innerText = result; // ...and then do the syntax highlighting again

          this.get("emberFreestyle").highlight(pre);
        }
      }

      return result;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.dynamicProperties = this.dynamicProperties || {};
    },
    didInsertElement: function didInsertElement() {
      var pre = this.element.querySelector("pre");

      if (pre && this.get("renderableSource")) {
        this.get("emberFreestyle").highlight(pre);
      }

      this.set("initialHighlightComplete", true);
    }
  });

  _exports.default = _default;
});