define("ember-freestyle/components/freestyle-annotation", ["exports", "ember-freestyle/templates/components/freestyle-annotation"], function (_exports, _freestyleAnnotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FreestyleAnnotation = Ember.Component.extend({
    layout: _freestyleAnnotation.default,
    classNames: ['FreestyleAnnotation'],
    classNameBindings: ['showNotes:is-showing:is-hidden'],
    emberFreestyle: Ember.inject.service(),
    showNotes: Ember.computed('emberFreestyle.{showNotes,focus}', 'slug', function () {
      var slug = this.get('slug');
      var focus = this.get('emberFreestyle.focus');

      if (focus && slug) {
        return slug.match(focus);
      }

      return this.get('emberFreestyle.showNotes');
    })
  });
  FreestyleAnnotation.reopenClass({
    positionalParams: ['slug']
  });
  var _default = FreestyleAnnotation;
  _exports.default = _default;
});