define("ember-data-table/components/text-search", ["exports", "ember-data-table/templates/components/text-search"], function (_exports, _textSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _textSearch.default,
    filter: '',
    classNames: ["data-table-search"],
    internalValue: Ember.computed.oneWay('filter'),
    auto: true,
    placeholder: 'Search',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('value', this.get('filter'));
    },
    onValueChange: Ember.observer('value', function () {
      this._valuePid = Ember.run.debounce(this, this.get('_setFilter'), this.get('wait'));
    }),
    onFilterChange: Ember.observer('filter', function () {
      // update value if filter is update manually outsite this component
      if (!this.isDestroying && !this.isDestroyed && !Ember.isEqual(this.get('filter'), this.get('value'))) {
        this.set('value', this.get('filter'));
      }
    }),
    _setFilter: function _setFilter() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('filter', this.get('value'));
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._valuePid);
    }
  });

  _exports.default = _default;
});