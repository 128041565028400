define("ember-data-table/components/data-table-content-body", ["exports", "ember-data-table/templates/components/data-table-content-body"], function (_exports, _dataTableContentBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tbody',
    init: function init() {
      this._super.apply(this, arguments);

      if (!this['data-table']) this.set('data-table', {});
      if (!this['content']) this.set('content', []);
    },
    layout: _dataTableContentBody.default,
    offset: Ember.computed('data-table.{page,size}', function () {
      var offset = 1; //to avoid having 0. row

      var page = this.get('data-table.page');
      var size = this.get('data-table.size');

      if (page && size) {
        offset += page * size;
      }

      return offset;
    }),
    wrappedItems: Ember.computed('content', 'content.[]', 'data-table.selection.[]', function () {
      var selection = this.get('data-table.selection') || [];
      var content = this.get('content') || [];
      return content.map(function (item) {
        return {
          item: item,
          isSelected: selection.includes(item)
        };
      });
    }),
    actions: {
      updateSelection: function updateSelection(selectedWrapper, event) {
        var _this = this;

        Ember.set(selectedWrapper, 'isSelected', event.target.checked);
        this.get('wrappedItems').forEach(function (wrapper) {
          if (wrapper.isSelected) {
            _this.get('data-table').addItemToSelection(wrapper.item);
          } else {
            _this.get('data-table').removeItemFromSelection(wrapper.item);
          }
        });
      }
    }
  });

  _exports.default = _default;
});