define("ember-freestyle/components/freestyle-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FreestyleNote = Ember.Component.extend({
    classNames: ['FreestyleNote']
  });
  FreestyleNote.reopenClass({
    positionalParams: ['slug']
  });
  var _default = FreestyleNote;
  _exports.default = _default;
});