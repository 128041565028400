define("ember-freestyle/components/freestyle-dynamic", ["exports", "ember-freestyle/templates/components/freestyle-dynamic"], function (_exports, _freestyleDynamic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    layout: _freestyleDynamic.default,
    classNames: ['FreestyleDynamic'],
    headerTitle: 'Dynamic Properties:',
    // Need this separate property for freestyle-dynamic's dynamic.<property> to work
    dynamicPropertyValues: Ember.computed('dynamicProperties', function () {
      var dynamicPropertyValues = {};
      var dynamicProperties = this.get('dynamicProperties');
      Object.keys(dynamicProperties).forEach(function (propertyName) {
        dynamicPropertyValues[propertyName] = Ember.get(dynamicProperties, "".concat(propertyName, ".value"));
      });
      return dynamicPropertyValues;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var dynamicProperties = this.get('dynamicProperties');

      if (dynamicProperties) {
        (false && !(_typeof(dynamicProperties) === 'object') && Ember.assert("dynamicProperties passed into freestyle-dynamic must be an object.  You passed: ".concat(dynamicProperties), _typeof(dynamicProperties) === 'object'));
      } else {
        this.dynamicProperties = {};
      }
    },
    actions: {
      changeValueTo: function changeValueTo(property, newValue) {
        // Make a copy and then reset dynamicProperties, to force template changes
        var dynamicProperties = Ember.assign({}, this.get('dynamicProperties'));
        Ember.set(dynamicProperties, "".concat(property, ".value"), newValue);
        this.set('dynamicProperties', dynamicProperties);
      }
    }
  }).reopenClass({
    positionalParams: ['slug']
  });

  _exports.default = _default;
});