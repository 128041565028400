define("ember-freestyle/templates/components/freestyle-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5ubAeaE",
    "block": "{\"symbols\":[\"section\",\"subsection\"],\"statements\":[[10,\"ul\"],[14,0,\"FreestyleMenu\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"FreestyleMenu-item\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"query\"],[\"FreestyleMenu-itemLink\",[30,[36,0],null,[[\"s\",\"ss\",\"f\"],[null,null,null]]]]],[[\"default\"],[{\"statements\":[[2,\"      All\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"FreestyleMenu-item\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"query\"],[\"FreestyleMenu-itemLink\",[30,[36,0],null,[[\"f\",\"s\",\"ss\"],[null,[32,1,[\"name\"]],null]]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"subsections\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"ul\"],[14,0,\"FreestyleMenu-submenu\"],[12],[2,\"\\n          \"],[10,\"li\"],[14,0,\"FreestyleMenu-submenuItem\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"query\"],[\"FreestyleMenu-submenuItemLink\",[30,[36,0],null,[[\"f\",\"s\",\"ss\"],[null,[32,1,[\"name\"]],[32,2,[\"name\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"              \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-hash\",\"link-to\",\"-track-array\",\"each\",\"menu\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-menu.hbs"
    }
  });

  _exports.default = _default;
});