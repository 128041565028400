define("ember-data-table/components/number-pagination", ["exports", "ember-data-table/templates/components/number-pagination"], function (_exports, _numberPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _numberPagination.default,
    classNames: ['data-table-pagination'],
    currentPage: Ember.computed('page', {
      get: function get() {
        return this.get('page') ? parseInt(this.get('page')) + 1 : 1;
      },
      set: function set(key, value) {
        this.set('page', value - 1);
        return value;
      }
    }),
    firstPage: Ember.computed('links', function () {
      return this.get('links.first.number') || 1;
    }),
    lastPage: Ember.computed('links', function () {
      var max = this.get('links.last.number') || -1;
      return max ? max + 1 : max;
    }),
    isFirstPage: Ember.computed('firstPage', 'currentPage', function () {
      return this.get('firstPage') == this.get('currentPage');
    }),
    isLastPage: Ember.computed('lastPage', 'currentPage', function () {
      return this.get('lastPage') == this.get('currentPage');
    }),
    hasMultiplePages: Ember.computed('lastPage', function () {
      return this.get('lastPage') > 0;
    }),
    startItem: Ember.computed('size', 'currentPage', function () {
      return this.get('size') * (this.get('currentPage') - 1) + 1;
    }),
    endItem: Ember.computed('startItem', 'nbOfItems', function () {
      return this.get('startItem') + this.get('nbOfItems') - 1;
    }),
    pageOptions: Ember.computed('firstPage', 'lastPage', function () {
      var _this = this;

      var nbOfPages = this.get('lastPage') - this.get('firstPage') + 1;
      return Array.from(new Array(nbOfPages), function (val, index) {
        return _this.get('firstPage') + index;
      });
    }),
    actions: {
      changePage: function changePage(link) {
        this.set('page', link['number'] || 0);
      }
    }
  });

  _exports.default = _default;
});