define("ember-freestyle/components/freestyle-dynamic-input-select-option", ["exports", "ember-freestyle/templates/components/freestyle-dynamic-input-select-option"], function (_exports, _freestyleDynamicInputSelectOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleDynamicInputSelectOption.default,
    tagName: 'option',
    attributeBindings: ['optionValue:value', 'selected'],
    selected: Ember.computed('optionValue', 'selectedValue', function () {
      return this.get('optionValue') === this.get('selectedValue');
    })
  });

  _exports.default = _default;
});