define("ember-table/components/ember-thead/component", ["exports", "ember-table/-private/utils/default-to", "ember-table/-private/utils/observer", "ember-table/-private/utils/element", "ember-table/-private/utils/sort", "ember-table/-private/column-tree", "ember-table/components/ember-thead/template"], function (_exports, _defaultTo, _observer, _element, _sort, _columnTree, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
    The table header component. This component manages and receives the column
    definitions for the table, and yields for each row of headers that exists
    (in the case of subcolumns).
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{columns}} as |h|>
  
      </t.head>
  
      <t.body @rows={{rows}} />
    </EmberTable>
    ```
  
    @yield {object} head - the API object yielded by the table header
    @yield {Component} head.row - The table row component
    @class {{ember-thead}}
    @public
  */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'thead',

    /**
      The API object passed in by the table
       @argument api
      @required
      @type object
    */
    api: null,
    unwrappedApi: Ember.computed.or('api.api', 'api'),

    /**
      The column definitions for the table
       @argument columns
      @required
      @type array? ([])
    */
    columns: (0, _defaultTo.default)(function () {
      return [];
    }),

    /**
      An ordered array of the sorts applied to the table
      @argument sorts
      @type array? ([])
    */
    sorts: (0, _defaultTo.default)(function () {
      return [];
    }),

    /**
      An optional sort. If not specified, defaults to <sortMultiple>, which
      sorts by each `sort` in `sorts`, in order.
      @argument sortFunction
      @type function? (<sortMultiple>)
    */
    sortFunction: (0, _defaultTo.default)(function () {
      return _sort.sortMultiple;
    }),

    /**
      An ordered array of the sorts applied to the table
      @argument compareFunction
      @type function? (<compareValues>)
    */
    compareFunction: (0, _defaultTo.default)(function () {
      return _sort.compareValues;
    }),

    /**
      Flag that allows to sort empty values after non empty ones
      @argument sortEmptyLast
      @type boolean? (false)
    */
    sortEmptyLast: (0, _defaultTo.default)(false),

    /**
      Flag that toggles reordering in the table
      @argument enableReorder
      @type boolean? (true)
    */
    enableReorder: (0, _defaultTo.default)(true),

    /**
      Flag that toggles resizing in the table
      @argument enableResize
      @type boolean? (true)
    */
    enableResize: (0, _defaultTo.default)(true),

    /**
      Sets which column resizing behavior to use. Possible values are `standard`
      (resizing a column pushes or pulls all other columns) and `fluid` (resizing a
      column subtracts width from neighboring columns).
      @argument resizeMode
      @type string? ('standard')
    */
    resizeMode: (0, _defaultTo.default)(_columnTree.RESIZE_MODE.STANDARD),

    /**
      A configuration that controls how columns shrink (or extend) when total column width does not
      match table width. Behavior of column modification is as follows:
       * "equal-column": extra space is distributed equally among all columns
      * "first-column": extra space is added into the first column.
      * "last-column": extra space is added into the last column.
      * "nth-column": extra space is added into the column defined by `fillColumnIndex`.
       @argument fillMode
      @type string? ('equal-column')
    */
    fillMode: (0, _defaultTo.default)(_columnTree.FILL_MODE.EQUAL_COLUMN),

    /**
      A configuration that controls which column shrinks (or extends) when `fillMode` is
      'nth-column'. This is zero indexed.
       @argument fillColumnIndex
      @type number?
    */
    fillColumnIndex: null,

    /**
      Sets a constraint on the table's size, such that it must be greater than, less
      than, or equal to the size of the containing element.
      Valid values:
        * 'none'
        * 'eq-container'
        * 'gte-container'
        * 'lte-container'
       @argument widthConstraint
      @type string? ('none')
    */
    widthConstraint: (0, _defaultTo.default)(_columnTree.WIDTH_CONSTRAINT.NONE),

    /**
      A numeric adjustment to be applied to the constraint on the table's size.
       @argument containerWidthAdjustment
      @type number?
    */
    containerWidthAdjustment: null,

    /**
      An action that is sent when sorts is updated
      @argument onHeaderAction
      @type Action?
    */
    onHeaderAction: null,

    /**
      An action that is sent when sorts is updated
      @argument onUpdateSorts
      @type Action?
    */
    onUpdateSorts: null,

    /**
      An action that is sent when columns are reordered
      @argument onReorder
      @type Action?
    */
    onReorder: null,

    /**
      An action that is sent when columns are resized
      @argument onResize
      @type Action?
    */
    onResize: null,
    'data-test-row-count': Ember.computed.readOnly('wrappedRows.length'),
    init: function init() {
      this._super.apply(this, arguments);
      /**
       * A sensor object that sends events to this table component when table size changes. When table
       * is resized, table width & height are updated and other computed properties depending on them
       * also get updated.
       */


      this._tableResizeSensor = null;
      /**
        The map that contains column meta information for this table. Is meant to be
        unique to this table, which is why it is created here. In order to prevent
        memory leaks, we need to be able to clean the cache manually when the table
        is destroyed or updated, which is why we use a Map instead of WeakMap
      */

      this.columnMetaCache = new Map();
      this.columnTree = _columnTree.default.create({
        sendAction: this.sendAction.bind(this),
        columnMetaCache: this.columnMetaCache,
        containerWidthAdjustment: this.containerWidthAdjustment
      });

      this._updateApi();

      this._updateColumnTree();

      (0, _observer.addObserver)(this, 'sorts', this._updateApi);
      (0, _observer.addObserver)(this, 'sortFunction', this._updateApi);
      (0, _observer.addObserver)(this, 'reorderFunction', this._updateApi);
      (0, _observer.addObserver)(this, 'sorts', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'columns.[]', this._onColumnsChange);
      (0, _observer.addObserver)(this, 'fillMode', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'fillColumnIndex', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'resizeMode', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'widthConstraint', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableSort', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableResize', this._updateColumnTree);
      (0, _observer.addObserver)(this, 'enableReorder', this._updateColumnTree);
    },
    _updateApi: function _updateApi() {
      this.set('unwrappedApi.columnTree', this.columnTree);
      this.set('unwrappedApi.sorts', this.get('sorts'));
      this.set('unwrappedApi.sortFunction', this.get('sortFunction'));
      this.set('unwrappedApi.compareFunction', this.get('compareFunction'));
      this.set('unwrappedApi.sortEmptyLast', this.get('sortEmptyLast'));
    },
    _updateColumnTree: function _updateColumnTree() {
      this.columnTree.set('sorts', this.get('sorts'));
      this.columnTree.set('columns', this.get('columns'));
      this.columnTree.set('fillMode', this.get('fillMode'));
      this.columnTree.set('fillColumnIndex', this.get('fillColumnIndex'));
      this.columnTree.set('resizeMode', this.get('resizeMode'));
      this.columnTree.set('widthConstraint', this.get('widthConstraint'));
      this.columnTree.set('enableSort', this.get('enableSort'));
      this.columnTree.set('enableResize', this.get('enableResize'));
      this.columnTree.set('enableReorder', this.get('enableReorder'));
    },
    _onColumnsChange: function _onColumnsChange() {
      if (this.get('columns.length') === 0) {
        return;
      }

      this._updateColumnTree();

      Ember.run.scheduleOnce('actions', this, this.fillupHandler);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._container = (0, _element.closest)(this.element, '.ember-table');
      this.columnTree.registerContainer(this._container);
      this._tableResizeSensor = new ResizeSensor(this._container, Ember.run.bind(this, this.fillupHandler));
    },
    willDestroyElement: function willDestroyElement() {
      this._tableResizeSensor.detach(this._container);

      this.columnTree.destroy(); // Clean the column meta cache

      var _iterator = _createForOfIteratorHelper(this.columnMetaCache.entries()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
              column = _step$value[0],
              meta = _step$value[1];

          meta.destroy();
          this.columnMetaCache.delete(column);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this._super.apply(this, arguments);
    },
    enableSort: Ember.computed.notEmpty('onUpdateSorts'),
    wrappedRows: Ember.computed('columnTree.rows.[]', 'sorts.[]', 'headerActions.[]', 'fillMode', 'fillColumnIndex', function () {
      var _this = this;

      var rows = this.get('columnTree.rows');
      var sorts = this.get('sorts');
      var columnMetaCache = this.get('columnMetaCache');
      return Ember.A(rows.map(function (row) {
        var cells = Ember.A(row.map(function (columnValue) {
          var columnMeta = columnMetaCache.get(columnValue);
          return {
            columnValue: columnValue,
            columnMeta: columnMeta,
            sorts: sorts,
            sendUpdateSort: _this.sendUpdateSort.bind(_this)
          };
        }));
        return {
          cells: cells,
          isHeader: true
        };
      }));
    }),
    sendUpdateSort: function sendUpdateSort(newSorts) {
      this.sendAction('onUpdateSorts', newSorts);
    },
    fillupHandler: function fillupHandler() {
      if (this.isDestroying) {
        return;
      }

      this.get('columnTree').ensureWidthConstraint();
    }
  });

  _exports.default = _default;
});