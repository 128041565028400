define("ember-freestyle/templates/components/freestyle-typeface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CvIeArVm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"FreestyleTypeface-previewHero\"],[12],[2,\"Aa\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"FreestyleTypeface-previewSampleContainer\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"FreestyleTypeface-previewSample\"],[12],[2,\"A B C D E F G H I J K L M N O P Q R S T U V W X Y Z\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"FreestyleTypeface-previewSample\"],[12],[2,\"a b c d e f g h i j k l m n o p q r s t u v w x y z\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"FreestyleTypeface-previewSample\"],[12],[2,\"0 1 2 3 4 5 6 7 8 9\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-typeface.hbs"
    }
  });

  _exports.default = _default;
});