define("ember-froala-editor/helpers/froala-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.froalaMethod = froalaMethod;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function froalaMethod(methodName) {
    var helperHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    for (var _len = arguments.length, helperParams = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      helperParams[_key - 2] = arguments[_key];
    }

    (false && !(typeof methodName === 'string' && methodName.length > 0) && Ember.assert('{{froala-method}} helper needs a string method path as the first argument', typeof methodName === 'string' && methodName.length > 0)); // Create a closure to pass back as the "action" to be triggered

    return function froalaMethodClosure() {
      for (var _len2 = arguments.length, closureParams = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        closureParams[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      var editor = _typeof(closureParams[0]) === 'object' && closureParams[0].component ? closureParams[0] : this;
      (false && !(_typeof(editor) === 'object' && editor.component) && Ember.assert('{{froala-method}} helper cannot determine the editor instance', _typeof(editor) === 'object' && editor.component)); // Get the actual method using ember.get() so that nested paths work here

      var method = Ember.get(editor, methodName);
      (false && !(typeof method === 'function') && Ember.assert('{{froala-method}} helper needs a valid method path as the first argument', typeof method === 'function')); // Copy helperParams to pass into the method

      var methodParams = [].concat(helperParams); // The helper hash can be used to "replace" the param
      // values with params received from the event...

      for (var propName in helperHash) {
        // Determine the positions within each params arrays
        // which should be used for replacing. parseInt() in
        // case the value is a string integer, ex: "2".
        var helperPos = helperParams.indexOf(propName);
        var eventPos = parseInt(helperHash[propName]); // If the parameter "name" was found in the helperParams,
        // and the relative arguments contains something at that position,
        // then go ahead and replace the param value...

        if (helperPos !== -1 && closureParams.length >= eventPos) {
          methodParams[helperPos] = closureParams[eventPos - 1];
        }
      } // Trigger the Froala Editor method


      return method.apply(void 0, _toConsumableArray(methodParams));
    };
  }

  var _default = Ember.Helper.helper(function (_ref, hash) {
    var _ref2 = _toArray(_ref),
        methodName = _ref2[0],
        partial = _ref2.slice(1);

    return froalaMethod.apply(void 0, [methodName, hash].concat(_toConsumableArray(partial)));
  });

  _exports.default = _default;
});