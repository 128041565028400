define("ember-freestyle/components/freestyle-palette", ["exports", "ember-freestyle/templates/components/freestyle-palette"], function (_exports, _freestylePalette) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _freestylePalette.default,
    title: 'Freestyle Palette',
    colors: Ember.computed('colorPalette', function () {
      var _this = this;

      return Ember.A(Object.keys(this.get('colorPalette')).map(function (k) {
        return _this.get('colorPalette')[k];
      }));
    })
  });

  _exports.default = _default;
});