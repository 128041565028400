define("ember-freestyle/templates/components/freestyle-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i53B9/kf",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"FreestyleSource-title\"],[12],[2,\"\\n    Source\\n  \"],[13],[2,\"\\n  \"],[10,\"pre\"],[14,0,\"handlebars\"],[14,\"tabindex\",\"0\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"renderableSource\",\"if\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-source.hbs"
    }
  });

  _exports.default = _default;
});