define("ember-data-table/templates/components/th-sortable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+nYHUry0",
    "block": "{\"symbols\":[],\"statements\":[[11,\"span\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"inverseSorting\"],null],[12],[2,\"\\n  \"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"[\"],[1,[34,0]],[2,\"]\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[34,3]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"order\",\"action\",\"if\",\"label\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/th-sortable.hbs"
    }
  });

  _exports.default = _default;
});