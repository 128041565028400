define("ember-data-table/components/data-table-menu-general", ["exports", "ember-data-table/templates/components/data-table-menu-general"], function (_exports, _dataTableMenuGeneral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTableMenuGeneral.default
  });

  _exports.default = _default;
});