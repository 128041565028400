define("ember-freestyle/templates/components/freestyle-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0oVRhJaA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"FreestyleCollection-title\"],[12],[2,\"\\n    \"],[1,[34,4]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"variants\",\"activeKey\",\"onClickVariant\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"setKey\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,[[30,[36,9],null,[[\"variant\"],[[30,[36,8],[\"freestyle-variant\"],[[\"collection\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"activeKey\",\"variants\",\"freestyle-variant-list\",\"title\",\"hasLabels\",\"if\",\"showVariantList\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-collection.hbs"
    }
  });

  _exports.default = _default;
});