define("ember-freestyle/components/freestyle-usage", ["exports", "ember-freestyle/templates/components/freestyle-usage"], function (_exports, _freestyleUsage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FreestyleUsage = Ember.Component.extend({
    layout: _freestyleUsage.default,
    classNames: ['FreestyleUsage'],
    classNameBindings: ['inline:FreestyleUsage--inline'],
    emberFreestyle: Ember.inject.service(),
    showLabels: Ember.computed.alias('emberFreestyle.showLabels'),
    showCode: Ember.computed.alias('emberFreestyle.showCode'),
    show: Ember.computed('emberFreestyle.focus', 'slug', function () {
      var slug = this.get('slug');
      var focus = this.get('emberFreestyle.focus');

      if (focus && slug) {
        return slug.match(focus);
      }

      return true;
    }),
    defaultTheme: Ember.computed.alias('emberFreestyle.defaultTheme'),
    // highlightJsTheme - passed in
    computedTheme: Ember.computed('defaultTheme', 'highlightJsTheme', function () {
      return this.get('highlightJsTheme') || this.get('defaultTheme');
    }),
    hasLabels: Ember.computed.and('showLabels', 'title'),
    hasCode: Ember.computed('showCode', 'slug', 'source', function () {
      return this.get('showCode') && (this.get('slug') || this.get('source'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.dynamicProperties = this.dynamicProperties || {};
    },
    didInsertElement: function didInsertElement() {
      this.get('emberFreestyle').ensureHljsTheme(this.get('computedTheme'));
    },
    actions: {
      setFocus: function setFocus() {
        this.set('emberFreestyle.focus', this.get('slug'));
      }
    }
  });
  FreestyleUsage.reopenClass({
    positionalParams: ['slug']
  });
  var _default = FreestyleUsage;
  _exports.default = _default;
});