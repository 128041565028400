define("ember-freestyle/components/freestyle-menu", ["exports", "ember-freestyle/templates/components/freestyle-menu"], function (_exports, _freestyleMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleMenu.default,
    tagName: '',
    emberFreestyle: Ember.inject.service(),
    menu: Ember.computed.readOnly('emberFreestyle.menu')
  });

  _exports.default = _default;
});