define("ember-data-table/components/data-table", ["exports", "ember-data-table/templates/components/data-table"], function (_exports, _dataTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('selection') === undefined) this.set('selection', []);
    },
    layout: _dataTable.default,
    noDataMessage: 'No data',
    isLoading: false,
    lineNumbers: false,
    searchDebounceTime: 2000,
    enableLineNumbers: Ember.computed.bool('lineNumbers'),
    enableSelection: Ember.computed.oneWay('hasMenu'),
    selectionIsEmpty: Ember.computed('selection.[]', function () {
      return this.get('selection.length') === 0;
    }),
    enableSizes: true,
    size: 5,
    sizeOptions: Ember.computed('size', 'sizes', 'enableSizes', function () {
      if (!this.get('enableSizes')) {
        return null;
      } else {
        var sizeOptions = this.get('sizes') || [5, 10, 25, 50, 100];

        if (!sizeOptions.includes(this.get('size'))) {
          sizeOptions.push(this.get('size'));
        }

        sizeOptions.sort(function (a, b) {
          return a - b;
        });
        return sizeOptions;
      }
    }),
    hasMenu: false,
    // set from inner component, migth fail with nested if
    enableSearch: Ember.computed('filter', function () {
      return this.get('filter') || this.get('filter') === '';
    }),
    autoSearch: true,
    filterChanged: Ember.observer('filter', function () {
      this.set('page', 0);
    }),
    sizeChanged: Ember.observer('size', function () {
      this.set('page', 0);
    }),
    parsedFields: Ember.computed('fields', function () {
      var fields = this.get('fields');

      if (Ember.typeOf(fields) === 'string') {
        return fields.split(' ');
      } else {
        return fields || [];
      }
    }),
    addItemToSelection: function addItemToSelection(item) {
      this.get('selection').addObject(item);
    },
    removeItemFromSelection: function removeItemFromSelection(item) {
      this.get('selection').removeObject(item);
    },
    clearSelection: function clearSelection() {
      this.get('selection').clear();
    }
  });

  _exports.default = _default;
});