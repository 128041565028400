define("ember-data-table/mixins/route", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      filter: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    mergeQueryOptions: function mergeQueryOptions() {
      return {};
    },
    model: function model(params) {
      var options = {
        sort: params.sort,
        page: {
          number: params.page,
          size: params.size
        }
      }; // TODO: sending an empty filter param to backend returns []

      if (params.filter) {
        options['filter'] = params.filter;
      }

      _lodash.default.merge(options, this.mergeQueryOptions(params));

      return this.get('store').query(this.get('modelName'), options);
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor(this.routeName);
        controller.set('isLoadingModel', true);
        transition.promise.finally(function () {
          controller.set('isLoadingModel', false);
        });
        return true; // bubble the loading event
      }
    }
  });

  _exports.default = _default;
});