define("ember-data-table/components/data-table-menu", ["exports", "ember-data-table/templates/components/data-table-menu"], function (_exports, _dataTableMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTableMenu.default,
    classNames: ['data-table-menu']
  });

  _exports.default = _default;
});