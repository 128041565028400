define("ember-freestyle/services/ember-freestyle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    showLabels: true,
    showNotes: true,
    showCode: true,
    showMenu: true,
    defaultTheme: 'zenburn',
    // must be explicitly set to null here for (query-params s=null ss=null f=null) to work
    section: null,
    subsection: null,
    focus: null,
    notFocused: Ember.computed.not('focus'),
    hljsVersion: '9.12.0',
    hljsPromise: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.hljsLanguagePromises = {};
    },
    hljsUrl: function hljsUrl() {
      return "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/".concat(this.hljsVersion, "/highlight.min.js");
    },
    hljsThemeUrl: function hljsThemeUrl(theme) {
      return "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/".concat(this.hljsVersion, "/styles/").concat(theme, ".min.css");
    },
    hljsLanguageUrl: function hljsLanguageUrl(language) {
      return "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/".concat(this.hljsVersion, "/languages/").concat(language, ".min.js");
    },
    ensureHljs: function ensureHljs() {
      var _this = this;

      if (!this.hljsPromise) {
        this.hljsPromise = new Ember.RSVP.Promise(function (resolve) {
          var src = _this.hljsUrl();

          var script = document.createElement('script');
          script.type = 'application/javascript';
          script.src = src;
          script.onload = resolve;
          document.body.appendChild(script);
        });
      }

      return this.hljsPromise;
    },
    highlight: function highlight(el) {
      var _this2 = this;

      this.ensureHljs().then(function () {
        return _this2.ensureHljsLanguage('handlebars');
      }).then(function () {
        hljs.highlightBlock(el);
      });
    },
    ensureHljsTheme: function ensureHljsTheme(theme) {
      if (document.querySelector("[data-hljs-theme=".concat(theme, "]"))) {
        return;
      }

      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = this.hljsThemeUrl(theme);
      link.setAttribute('data-hljs-theme', "".concat(theme));
      document.head.appendChild(link);
    },
    ensureHljsLanguage: function ensureHljsLanguage(language) {
      var _this3 = this;

      if (!this.hljsLanguagePromises[language]) {
        this.hljsLanguagePromises[language] = new Ember.RSVP.Promise(function (resolve) {
          var src = _this3.hljsLanguageUrl(language);

          var script = document.createElement('script');
          script.type = 'application/javascript';
          script.src = src;
          script.onload = resolve;
          document.body.appendChild(script);
        });
      }

      return this.hljsLanguagePromises[language];
    },
    // menu - tree structure of named sections with named subsections
    registerSection: function registerSection(sectionName) {
      var subsectionName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var menu = this.get('menu') || Ember.A([]);

      if (!menu.filterBy('name', sectionName).length) {
        menu.push({
          name: sectionName,
          subsections: Ember.A([])
        });
      }

      if (Ember.isPresent(subsectionName)) {
        var section = menu.findBy('name', sectionName);

        if (!section.subsections.filterBy('name', subsectionName).length) {
          section.subsections.push({
            name: subsectionName
          });
        }
      }

      this.set('menu', menu);
    }
  });

  _exports.default = _default;
});