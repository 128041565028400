define("ember-data-table/mixins/serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create({
    /**
        Parse the links in the JSONAPI response and convert to a meta-object
    */
    normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
      var result = this._super.apply(this, arguments);

      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
      }

      if (payload.meta) {
        result.meta.count = payload.meta.count;
      }

      return result;
    },

    /**
       Transforms link URLs to objects containing metadata
       E.g.
       {
           previous: '/streets?page[number]=1&page[size]=10&sort=name
           next: '/streets?page[number]=3&page[size]=10&sort=name
       }
        will be converted to
        {
           previous: { number: 1, size: 10 },
           next: { number: 3, size: 10 }
       }
     */
    createPageMeta: function createPageMeta(data) {
      var meta = {};
      Object.keys(data).forEach(function (type) {
        var link = data[type];
        meta[type] = {};

        if (link) {
          //extracts from '/path?foo=bar&baz=foo' the string: foo=bar&baz=foo
          var query = link.split(/\?(.+)/)[1] || '';
          query.split('&').forEach(function (pairs) {
            var _pairs$split = pairs.split('='),
                _pairs$split2 = _slicedToArray(_pairs$split, 2),
                param = _pairs$split2[0],
                value = _pairs$split2[1];

            if (decodeURIComponent(param) === 'page[number]') {
              meta[type].number = parseInt(value);
            } else if (decodeURIComponent(param) === 'page[size]') {
              meta[type].size = parseInt(value);
            }
          });
        }
      });
      return meta;
    }
  });

  _exports.default = _default;
});