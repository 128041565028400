define("ember-freestyle/templates/components/freestyle-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZCETtB4J",
    "block": "{\"symbols\":[\"color\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"FreestylePalette\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"FreestylePalette-title\"],[12],[1,[34,1]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"FreestylePalette-description\"],[12],[1,[34,2]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"color\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"freestyle-palette-item\",\"title\",\"description\",\"colors\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-palette.hbs"
    }
  });

  _exports.default = _default;
});