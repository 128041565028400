define("ember-froala-editor/components/froala-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="fr-view">
    {{@content}}
    {{yield}}
  </div>
  */
  {"id":"QIhEiLWW","block":"{\"symbols\":[\"&attrs\",\"@content\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"fr-view\"],[12],[2,\"\\n  \"],[1,[32,2]],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"ember-froala-editor/components/froala-content.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});