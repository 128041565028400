define("ember-data-table/mixins/default-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    page: 0,
    size: 10,
    filter: ''
  });

  _exports.default = _default;
});