define("ember-data-table/templates/components/default-data-table-content-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EVPRcvws",
    "block": "{\"symbols\":[\"field\",\"&default\"],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[6,[37,4],null,[[\"tagName\",\"route\",\"model\"],[\"td\",[35,3],[35,0]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[[35,0],[35,2]],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"td\"],[12],[1,[30,[36,1],[[35,0],[35,2]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[12],[2,\"\\n\"],[2,\"    \"],[1,[30,[36,1],[[35,0],[32,1]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"get\",\"firstColumn\",\"linkedRoute\",\"link-to\",\"if\",\"otherColumns\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/default-data-table-content-body.hbs"
    }
  });

  _exports.default = _default;
});