define("ember-freestyle/components/freestyle-usage-controls", ["exports", "ember-freestyle/templates/components/freestyle-usage-controls"], function (_exports, _freestyleUsageControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleUsageControls.default,
    tagName: '',
    emberFreestyle: Ember.inject.service(),
    showLabels: Ember.computed.alias('emberFreestyle.showLabels'),
    showNotes: Ember.computed.alias('emberFreestyle.showNotes'),
    showCode: Ember.computed.alias('emberFreestyle.showCode'),
    showHeader: true,
    showFocus: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', function () {
        _this.set('focus', _this.get('emberFreestyle.focus'));
      });
    },
    actions: {
      toggleUsage: function toggleUsage(usageType) {
        var usageProperty = "emberFreestyle.show".concat(Ember.String.capitalize(usageType));
        this.toggleProperty(usageProperty);
      },
      setFocus: function setFocus() {
        this.set('emberFreestyle.focus', this.get('focus'));
      }
    }
  });

  _exports.default = _default;
});