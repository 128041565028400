define("ember-freestyle/helpers/equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.equalHelper = equalHelper;

  function equalHelper(params) {
    return params[0] === params[1];
  }
});