define("ember-data-table/templates/components/data-table-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LmLyx5YE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"general\",\"selected\"],[[30,[36,1],[\"data-table-menu-general\"],[[\"data-table\"],[[35,0]]]],[30,[36,1],[\"data-table-menu-selected\"],[[\"data-table\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data-table\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/data-table-menu.hbs"
    }
  });

  _exports.default = _default;
});