define("ember-data-table/templates/components/text-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dEbX27za",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],null,[[\"value\",\"placeholder\"],[[35,5],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"input\"],[15,2,[34,0]],[15,\"placeholder\",[34,1]],[15,\"oninput\",[30,[36,3],[[32,0],[30,[36,2],[[35,0]],null]],[[\"value\"],[\"target.value\"]]]],[12],[13],[2,\"\\n  \"],[11,\"button\"],[4,[38,3],[[32,0],[30,[36,2],[[35,4]],null],[35,0]],null],[12],[2,\"Search\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"internalValue\",\"placeholder\",\"mut\",\"action\",\"filter\",\"value\",\"input\",\"auto\",\"if\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/text-search.hbs"
    }
  });

  _exports.default = _default;
});