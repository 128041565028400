define("ember-freestyle/components/freestyle-guide", ["exports", "ember-freestyle/templates/components/freestyle-guide"], function (_exports, _freestyleGuide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleGuide.default,
    classNames: ['FreestyleGuide'],
    emberFreestyle: Ember.inject.service(),
    highlightJsTheme: Ember.computed.alias('emberFreestyle.defaultTheme'),
    showMenu: Ember.computed.alias('emberFreestyle.showMenu'),
    showAside: false,
    actions: {
      toggleNav: function toggleNav() {
        this.toggleProperty('showMenu');
      },
      toggleAside: function toggleAside() {
        this.toggleProperty('showAside');
      }
    }
  });

  _exports.default = _default;
});