define("ember-froala-editor/helpers/froala-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.froalaHtml = froalaHtml;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function froalaHtml(callback) {
    var returnSafeString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    for (var _len = arguments.length, partial = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      partial[_key - 2] = arguments[_key];
    }

    (false && !(typeof callback === 'function') && Ember.assert('{{froala-html}} helper requires a function as the first parameter', typeof callback === 'function'));
    return function froalaHtmlClosure() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      // Editor might be passed in as the first arg if also wrapped with {{froala-arg}}
      // Note: Shift editor off args so it isn't "double passed" when args are spread on the callback
      var editor = _typeof(args[0]) === 'object' && args[0].component ? args.shift() : this;
      (false && !(editor.html && typeof editor.html.get === 'function') && Ember.assert('{{froala-html}} helper cannot determine the editor instance', editor.html && typeof editor.html.get === 'function')); // Get the html content from the editor, making it a SafeString if needed

      var html = returnSafeString ? Ember.String.htmlSafe(editor.html.get()) : editor.html.get(); // Call the setter, passing in the html first then all others

      return callback.apply(void 0, [html, editor].concat(partial, args));
    };
  }

  var _default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = _toArray(_ref),
        callback = _ref3[0],
        partial = _ref3.slice(1);

    var _ref2$returnSafeStrin = _ref2.returnSafeString,
        returnSafeString = _ref2$returnSafeStrin === void 0 ? true : _ref2$returnSafeStrin;
    return froalaHtml.apply(void 0, [callback, returnSafeString].concat(_toConsumableArray(partial)));
  });

  _exports.default = _default;
});