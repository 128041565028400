define("ember-data-table/templates/components/data-table-menu-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3TxLq8sA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,2,[\"selectionIsEmpty\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"item-count\"],[12],[1,[34,0]],[2,\" item(s) selected\"],[13],[2,\"\\n  \"],[11,\"button\"],[4,[38,1],[[32,0],\"clearSelection\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n  \"],[18,1,[[30,[36,3],[0,[35,0],[35,2,[\"selection\"]]],null],[35,2]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectionCount\",\"action\",\"data-table\",\"slice\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-data-table/templates/components/data-table-menu-selected.hbs"
    }
  });

  _exports.default = _default;
});